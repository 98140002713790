/**
 * @license
 *
 * Font Family: Cabinet Grotesk
 * Designed by: ITF
 * URL: https://www.fontshare.com/fonts/cabinet-grotesk
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Cabinet Grotesk Variable(Variable font)
 * Cabinet Grotesk Thin
 * Cabinet Grotesk Extralight
 * Cabinet Grotesk Light
 * Cabinet Grotesk Regular
 * Cabinet Grotesk Medium
 * Cabinet Grotesk Bold
 * Cabinet Grotesk Extrabold
 * Cabinet Grotesk Black
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 100.0 to 900.0)

*/

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('../fonts/CabinetGrotesk-Regular.woff2') format('woff2'),
    url('../fonts/CabinetGrotesk-Regular.woff') format('woff'),
    url('../fonts/CabinetGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('../fonts/CabinetGrotesk-Medium.woff2') format('woff2'),
    url('../fonts/CabinetGrotesk-Medium.woff') format('woff'),
    url('../fonts/CabinetGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('../fonts/CabinetGrotesk-Bold.woff2') format('woff2'),
    url('../fonts/CabinetGrotesk-Bold.woff') format('woff'), url('../fonts/CabinetGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('../fonts/CabinetGrotesk-Extrabold.woff2') format('woff2'),
    url('../fonts/CabinetGrotesk-Extrabold.woff') format('woff'),
    url('../fonts/CabinetGrotesk-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
