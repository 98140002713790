


html,
body
  position: relative
  height: 100%
  min-height: 100%
  background-size: cover
  min-width: 320px

body
  display: block
  font-family: 'Proxima Nova', sans-serif
  overflow-x: hidden
  overflow-y: auto

*,
*:after,
*:before
  box-sizing: border-box

*:focus
  outline: 0
