.root,
input
  background: none
  // color: inherit
  font: inherit
  outline: 0

.root
  display: inline-block
  width: 100%

input
  &[disabled],
  &[readonly]
    cursor: default

  &:-webkit-autofill
    -webkit-box-shadow: 0 0 0 1000px #fff inset

  &[type=number]
    -moz-appearance: textfield
    margin: 0

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0

  &::-ms-clear
    display: none
    width: 0
    height: 0

  &::placeholder
    text-transform: none
